import React from 'react';
import './gauge.css';
class Gauge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  render() {
    // const { selectedOption } = this.state;
    const inverted = this.props.invert ? true : false;
    const value = this.props.value ? this.props.value : 0;
    const label = this.props.label ? this.props.label.toUpperCase() : '';
    let visibleText = value;
    const max = this.props.max ? this.props.max : 0;
    if (inverted === true) {
      visibleText = max - this.props.value;
    }
    // visibleText =  + visibleText;
    let degrees = 0;
    if (inverted === false) degrees = (value / max) * 180;
    else degrees = ((max - value) / max) * 180;
    const hideValue = this.props.hideValue ? this.props.hideValue : false;

    return (
      <div>
        <div className="circle-wrap">
          <div className="circle">
            <div className="mask full" style={{ transform: 'rotate(' + degrees + 'deg)' }}>
              <div className="fill" style={{ transform: 'rotate(' + degrees + 'deg)' }}></div>
            </div>
            <div className="mask half">
              <div className="fill" style={{ transform: 'rotate(' + degrees + 'deg)' }}></div>
            </div>
            <div className="inside-circle">
              <label className="inside-text">
                {label}
                {<br></br>}
                {hideValue === false ? visibleText : null}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Gauge;

//
