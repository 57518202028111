import React from 'react';

class CustomCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };

    this.input = React.createRef();
  }
  state = {
    selectedOption: null,
  };
  handleChange = (id) => {
    let checkedState = document.getElementById(this.props.inputId).checked;

    document.getElementById(this.props.inputId).checked = !checkedState;
    this.props.checkedChange(this.input.current.checked, id);
  };

  render() {
    // const { selectedOption } = this.state;

    return (
      <div className="checkBoxItem">
        <input
          id={this.props.inputId}
          key={this.props.inputId}
          className="checkBox"
          type="checkbox"
          onChange={() => this.props.checkedChange(this.input.current.checked, this.props.id)}
          defaultChecked={this.props.checked}
          ref={this.input}
        />
        <label className="checkBoxLabel" style={{ color: 'white', margin: '5px' }} onClick={() => this.handleChange(this.props.id)}>
          {this.props.msg}
        </label>
      </div>
    );
  }
}
export default CustomCheckBox;
