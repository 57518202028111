import React from 'react';
import './SideBar.css';
import CustomCheckBox from '../../components/checkbox/CustomCheckBox';

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      width: '',
      hidedProperties: {},
    };
  }

  componentDidUpdate() {}

  closeNav() {
    const { sidebarOpen, width } = this.state;

    this.setState({ sidebarOpen: false, width: '0px' });
    console.log('close', sidebarOpen, width);
    document.getElementById('mySidebar').style.width = '0px';
  }

  typeFilterChange = (checked, id) => {
    console.log('filterChange', checked, id);
    let { hidedProperties } = this.state;
    hidedProperties[id] = checked;

    let freeParking = document.getElementById('freeParking').checked;
    let discParking = document.getElementById('discParking').checked;
    let tollParking = document.getElementById('tollParking').checked;
    let specialParking = document.getElementById('specialParking').checked;
    let privateArea = document.getElementById('privateArea').checked;
    let bicycle = document.getElementById('bicycle').checked;
    if (!freeParking || !discParking || !tollParking || !specialParking || !privateArea) document.getElementById('allFilters').checked = false;
    let filters = {};
    filters['paymentType'] = {};
    filters['reservedForTypes'] = {};
    filters['paymentType']['freeParking'] = !freeParking;
    filters['paymentType']['discParking'] = !discParking;
    filters['paymentType']['tollParking'] = !tollParking;
    filters['paymentType']['specialParking'] = !specialParking;
    filters['paymentType']['privateArea'] = !privateArea;
    if (bicycle === true) filters['reservedForTypes']['bicycle'] = !bicycle;
    this.props.typeFilterChange(filters);
  };

  allFiltersChange = (checked, id) => {
    console.log('filterChange', checked, id);
    let { hidedProperties } = this.state;
    hidedProperties[id] = checked;

    document.getElementById('freeParking').checked = checked;
    document.getElementById('discParking').checked = checked;
    document.getElementById('tollParking').checked = checked;
    document.getElementById('specialParking').checked = checked;
    document.getElementById('privateArea').checked = checked;
    //   document.getElementById('bicycle').checked = checked;

    let filters = {};
    filters['paymentType'] = {};
    filters['reservedForTypes'] = {};
    filters['paymentType']['freeParking'] = !checked;
    filters['paymentType']['discParking'] = !checked;
    filters['paymentType']['tollParking'] = !checked;
    filters['paymentType']['specialParking'] = !checked;
    filters['paymentType']['privateArea'] = !checked;
    if (document.getElementById('bicycle').checked === true) filters['reservedForTypes']['bicycle'] = !document.getElementById('bicycle').checked;
    this.props.typeFilterChange(filters);
  };

  render() {
    const { t } = this.props;
    const { width } = this.state;
    return (
      <div id="mySidebar" className="sidebar" style={{ width: width }}>
        <button className="closebtn" onClick={() => this.closeNav()}>
          X
        </button>
        <div className="filters">
          <CustomCheckBox className="filterItem" inputId="allFilters" msg={t('allFilters')} checked={true} checkedChange={this.allFiltersChange}></CustomCheckBox>
          <hr></hr>
          <CustomCheckBox inputId="freeParking" msg={t('freeParking')} checked={true} checkedChange={this.typeFilterChange}></CustomCheckBox>
          <CustomCheckBox inputId="discParking" msg={t('discParking')} checked={true} checkedChange={this.typeFilterChange}></CustomCheckBox>
          <CustomCheckBox inputId="tollParking" msg={t('tollParking')} checked={true} checkedChange={this.typeFilterChange}></CustomCheckBox>
          <CustomCheckBox inputId="specialParking" msg={t('specialParking')} checked={true} checkedChange={this.typeFilterChange}></CustomCheckBox>
          <CustomCheckBox inputId="privateArea" msg={t('privateArea')} checked={true} checkedChange={this.typeFilterChange}></CustomCheckBox>
          <hr></hr>
          <CustomCheckBox inputId="bicycle" msg={t('bicycle')} checked={false} checkedChange={this.typeFilterChange}></CustomCheckBox>
        </div>

        <hr></hr>
        <div className="links">
          <button className="feedbackButton" onClick={this.props.onClick}>
            {t('feedback')}
          </button>
        </div>
      </div>
    );
  }
}

export default SideBar;

/*

 <hr></hr>
          <CustomCheckBox id="typeCDparking" msg={t('typeCDparking')} checked={true} checkedChange={this.filterChange}></CustomCheckBox>
 <button className="feedbackButton" onClick={this.props.onClick}>
            {t('feedback')}
          </button>


           <a href={'https://etsiparkki.fi/info/infopages/' + this.props.language}>{t('info')}</a>
*/
