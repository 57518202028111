import join from 'url-join';
import { validateResponse, toJson } from '../api/apiUtils';
import { API_ROOT } from '../api/apiUtils';

const DEVICE_ROOT = join(API_ROOT, 'events');

/**
 * send an event
 * @returns { Promise }
 */
export function postEvent(eventId, content) {
  const path = join(DEVICE_ROOT, 'event');
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ id: eventId, data: content }),
  };

  return fetch(path, options).then(validateResponse).then(toJson);
}
