import React from 'react';
import { BsSearch } from 'react-icons/bs';
import './SearchBar.css';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      lastChangeTime: new Date().getTime(),
      lastRequestTime: new Date().getTime(),
      inputText: '',
      filteredOptions: [],
      showOptions: false,
      activeOption: 0,
      activeItem: {},
    };

    this.input = React.createRef();
  }
  state = {
    selectedOption: null,
  };
  handleChange = (event) => {
    const { lastChangeTime } = this.state;

    console.log(`searchBarInput:`, event.target.value, lastChangeTime);
    this.setState({ lastChangeTime: new Date().getTime(), inputText: event.target.value });

    if (event.target.value.length > 2) {
      this.setState({ showOptions: true });
    } else {
      this.setState({ showOptions: false });
    }
    //  this.props.handleChange(selectedOption);
    setTimeout(() => {
      const { lastChangeTime, inputText, lastRequestTime } = this.state;
      if (lastChangeTime + 350 < new Date().getTime() && lastRequestTime + 100 < new Date().getTime()) {
        if (inputText.length > 2) {
          if (this.props.autofillFetch) {
            this.setState({ lastRequestTime: new Date().getTime() });
            this.props.autofillFetch(inputText);
          }
        }
      }
    }, 500);
  };

  handleSubmitChange = (event) => {
    this.setState({ showOptions: false });
    let itemToSearch = document.getElementById('searchBarInput').value;
    let found = false;
    let selectedItem = {};
    for (let item of this.props.filteredOptions) {
      if (item.key === itemToSearch) {
        //   console.log('found searchItem', item);
        selectedItem = item;
        found = true;
      }
    }
    if (!found) {
      if (this.props.filteredOptions && this.props.filteredOptions.length && this.props.filteredOptions.length > 0) {
        //   console.log('not found match for search...using first from list', this.props.filteredOptions[0]);
        selectedItem = this.props.filteredOptions[0];
      }
    }

    if (this.props.selectedItem) this.props.selectedItem(selectedItem);
  };

  onClick = (item) => {
    this.setState({
      activeOption: 0,
      showOptions: false,
      inputText: item.key,
    });
    document.getElementById('searchBarInput').value = item.key;
    this.handleSubmitChange();
    //   console.log('list click');
  };

  componentDidUpdate() {
    //  console.log('filteredOptions', this.props.filteredOptions);
  }

  render() {
    const { showOptions, activeOption } = this.state;

    let optionList;
    if (showOptions) {
      if (this.props.filteredOptions && this.props.filteredOptions.length) {
        optionList = (
          <ul className="options">
            {this.props.filteredOptions.map((item, index) => {
              let className;
              if (index === activeOption) {
                className = 'option-active';
              }
              return (
                <li className="filteredItem" key={item.key} onClick={this.onClick.bind(this, item)}>
                  {item.key}, {item.city}
                </li>
              );
            })}
          </ul>
        );
      } else {
        optionList = (
          <div className="no-options">
            <em>.......</em>
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <div className="searchBar">
          <input type="text" className="searchBarInput" id="searchBarInput" onChange={this.handleChange} placeholder={this.props.searchPlaceHolder} autoComplete="off"></input>
          <button className="searchBarButton" type="submit" onClick={this.handleSubmitChange}>
            <BsSearch></BsSearch>
          </button>
        </div>
        {optionList}
      </React.Fragment>
    );
  }
}
export default SearchBar;
