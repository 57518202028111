import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postEvent } from '../../api/events';

import './FeedBack.css';

function FeedBackLevel(props) {
  return (
    <b className="feedBackLevelItem" id={'smileylevel' + props.id} style={{ fontSize: '26px' }} onClick={() => props.onCLick(props.id)}>
      {props.content}
    </b>
  );
}
/*
function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}*/

export function FeedBack(props) {
  const { t, i18n } = useTranslation();
  let smileyLevel = 0;

  const [contentError, setContentError] = useState(null);
  const [contentSuccess, setContentSuccess] = useState(false);
  let language = props.match.language ? props.match.language : 'fi';
  if (language !== i18n.language) i18n.changeLanguage(language);
  //useEffect(() => {}, []);

  const clickHandler = (id) => {
    let i;
    for (i = 1; i < 6; i++) {
      let itemId = 'smileylevel' + i.toString();
      document.getElementById(itemId).style.backgroundColor = 'rgb(243, 240, 240, 0)';
    }
    document.getElementById('smileylevel' + id).style.backgroundColor = 'rgba(39, 39, 214, 0.575)';
    smileyLevel = id;
  };

  const submitHandler = () => {
    if (contentSuccess === true) {
      return;
    }
    console.log('submit!', smileyLevel);
    if (smileyLevel === 0) {
      console.warn('no selected level found');

      if (contentError !== true) setContentError(true);
    } else {
      if (contentError !== false) setContentError(false);
      setContentSuccess(true);
      //  document.getElementById('feedbackFromSubmitBtn').textContent = 'Kiitos!';
      let feedbackText = document.getElementById('feedBackMessage').value;
      console.debug(feedbackText);
      postEvent('parking.general.feedback.service', {
        actionType: 'feedback',
        target: window.location.href,
        referrer: document && document.referrer ? document.referrer : 'n/a',
        feedbackLevel: smileyLevel,
        feedbackText: feedbackText,
      }).then((result) => {});
      // await timeout(1200);
      //  window.history.back(); //exit smoothly
    }
  };

  return (
    <div
      className="feedbackForm"
      style={{
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        //width: '100%',
        // height: '100%',
        //    alignItems: 'center',
        //     justifyContent: 'center',
        //      postition: 'absolute',
        //flexFlow: 'row wrap',
      }}>
      <div
        className="feedBackFormHeader"
        key="feedBackFormHeader"
        style={{
          maxWidth: '100%',
          width: '100%',
          maxHeight: '9vh',
          height: '9vh',
          //float: 'left',
          //  flex: '1 0 100%',
          //    flexGrow: 1,
          //  position: 'relative',
        }}>
        <div>
          <h3>{t('feedbackFormHeader')}</h3>
        </div>
      </div>

      <div
        className="feedBackFormSmiley"
        key="feedBackFormSmiley"
        style={{
          maxWidth: '100%',
          width: '100%',
          maxHeight: '10vh',
          height: '10vh',
          //float: 'left',
          //  flex: '1 0 100%',
          //    flexGrow: 1,
          //  position: 'relative',
        }}>
        <div className="smileyFaceRow">
          <FeedBackLevel id="1" content={'😠'} onCLick={clickHandler}></FeedBackLevel>
          <FeedBackLevel id="2" content={'😕'} onCLick={clickHandler}></FeedBackLevel>
          <FeedBackLevel id="3" content={'😐'} onCLick={clickHandler}></FeedBackLevel>
          <FeedBackLevel id="4" content={'😊'} onCLick={clickHandler}></FeedBackLevel>
          <FeedBackLevel id="5" content={'😃'} onCLick={clickHandler}></FeedBackLevel>
        </div>
      </div>
      <div
        className="feedBackFormFreeText"
        key="feedBackFormFreeText"
        style={{
          maxWidth: '100%',
          width: '100%',
          maxHeight: '40vh',
          height: '40vh',
          //float: 'left',
          //  flex: '1 0 100%',
          //    flexGrow: 1,
          //  position: 'relative',
        }}>
        <h3 className="feedbackLabel">{t('otherFeedback')}</h3>
        <textarea
          id="feedBackMessage"
          name="feedBackMessage"
          maxLength={400}
          style={{ resize: 'none', width: '50vh', height: '35vh', border: 'solid 2px rgba(36, 36, 37, 0.5)' }}></textarea>
      </div>
      <div
        className="feedBackFormSubmit"
        key="feedBackFormSubmit"
        style={{
          maxWidth: '100%',
          width: '100%',
          maxHeight: '20vh',
          height: '20vh',
        }}>
        <br></br>
        <button className="feedbackFromSubmitBtn" onClick={() => submitHandler()}>
          {t('submitButton')}
        </button>
        <div>
          {contentError === true && <b>{t('missingFeedback')}</b>}
          {contentSuccess === true && <b>{t('feedbackThanks')}</b>}
        </div>
      </div>
      <div
        className="feedbackLinks"
        style={{
          maxWidth: '100%',
          width: '100%',
          maxHeight: '4vh',
          height: '4vh',
        }}>
        <div className="feedBackLinkItems">
          <a href="https://etsiparkki.fi">
            <b>{t('linkToMainPageText')}</b>
          </a>
        </div>
      </div>
    </div>
  );
}
