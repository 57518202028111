import { createSelector } from 'reselect';

const mainPageState = (state) => state.mainPage;

export const makeSelectProcessDatas = createSelector(mainPageState, (mainPage) => mainPage.processDatas);

export const makeSelectGeoLocation = createSelector(mainPageState, (mainPage) => mainPage.geoLocation);

export const makeSelectFilter = createSelector(mainPageState, (mainPage) => mainPage.filter);

export const makeSelectAutocomplete = createSelector(mainPageState, (mainPage) => mainPage.autocomplete);

export const makeSelectTargetLocation = createSelector(mainPageState, (mainPage) => mainPage.targetLocation);

export const makeSelectLanguage = createSelector(mainPageState, (mainPage) => mainPage.language);
