import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _value: null,
    };
  }
  componentDidUpdate() {
    //console.log('props:', this.props);
  }
  componentDidMount() {
    this.setState({ _value: this.props.value });
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ _value: event.target.value });
    if (this.props.valueChange) this.props.valueChange(event.target.value, event);
  };

  render() {
    const { _value } = this.state;
    const controlMode = this.props.controlMode ? this.props.controlMode : 'internal'; // external or internal
    let value = _value === null && this.props.value ? this.props.value : _value !== null ? _value : '';
    if (controlMode === 'external') value = this.props.value;
    const label = this.props.label ? this.props.label : '';
    const style = this.props.style ? this.props.style : {};
    const items = this.props.items ? this.props.items : [];
    // console.log(value, label);
    return (
      <div>
        <FormControl fullWidth>
          <InputLabel id="simple-select-label">{label}</InputLabel>
          <Select
            style={style}
            autoWidth
            labelId="simple-select-label"
            id="simple-select"
            value={value}
            //      variant={'filled'}
            label={label}
            onChange={this.handleChange}
            sx={{ bgcolor: 'background.paper', height: '40px' }}>
            {items.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default DropDown;
