import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import './ImageButton.css';

//import { Button } from 'react-bootstrap';

class ImageButton extends Component {
  static defaultProps = {};

  static propTypes = {
    initialValue: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  buttonClick() {
    console.log('click');
    // this.setState({ active: !this.state.active });
  }
  // Tässä tunnistetaan propsien muutos ja voidaan esim. alustaa state uudestaan.
  componentDidUpdate(prevProps, prevState) {}

  render() {
    //let btn_class = this.state.active ? 'activeButton' : 'notActiveButton';

    return (
      <div className="imageButton">
        <img className="imageButtonImage" src={this.props.src} alt="btnImg" onClick={this.props.onClick} />
      </div>
    );
  }
}

export default ImageButton;

/*
   

*/
