import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { makeSelectProcessDatas, makeSelectGeoLocation, makeSelectFilter, makeSelectAutocomplete, makeSelectTargetLocation, makeSelectLanguage } from './selectors';
import Axios from 'axios';
import { setProcessDatas, setGeoLocation, setFilter, setAutocomplete, setTargetLocation, setLanguage } from './actions';
import { useDispatch } from 'react-redux';
import { FiMenu } from 'react-icons/fi';
import SideBar from './SideBar';
import SearchBar from '../../components/search/SearchBar';
import ImageButton from '../../components/buttons/ImageButton';
import { useTranslation, withTranslation } from 'react-i18next';
import { postEvent } from '../../api/events';
import { MdDirectionsBike } from 'react-icons/md';
import DropDown from '../../components/dropdown/DropDown';

import { getAutoComplete } from '../../api/address';

import MainMap from './MainMap.js';
//import LocationSearchInput from '../../components/forms/LocationSearchInput';
import './MainPage.css';
//import { FeedBack } from '../Feedback/FeedBack.js';

const actionDispatchProcessData = (dispatch) => ({
  setProcessData: (processDatas) => dispatch(setProcessDatas(processDatas)),
});

const actionDispatchGeoLocation = (dispatch) => ({
  setGeoLocation: (geoLocation) => dispatch(setGeoLocation(geoLocation)),
});

const actionDispatchFilter = (dispatch) => ({
  setFilter: (filter) => dispatch(setFilter(filter)),
});

const actionDispatchAutocomplete = (dispatch) => ({
  setAutocomplete: (autocomplete) => dispatch(setAutocomplete(autocomplete)),
});

const actionDispatchTargetLocation = (dispatch) => ({
  setTargetLocation: (autocomplete) => dispatch(setTargetLocation(autocomplete)),
});

const actionDispatchLanguage = (dispatch) => ({
  setLanguage: (language) => dispatch(setLanguage(language)),
});

var isLoaded = false;

const SideBarT = withTranslation()(SideBar);

export function MainPage(props) {
  const { t, i18n } = useTranslation();
  var currentLanguage = i18n.language;
  var feedbackVisible = false;
  const homeDomain = window.location.href;
  const localStorageLanguage = window.localStorage.getItem('iotAppLanguage');

  const isValidDomain = true;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    window.localStorage.setItem('iotAppLanguage', lng);
  };
  if (props.match && props.match && props.match && Object.keys(props.match).length > 0) {
    console.debug('mainpage params', props.match.params);
  }
  const targetCity = props.match.city ? props.match.city : 'turku';
  const targetLat = props.match.lat ? parseFloat(props.match.lat) : 0;
  const targetLon = props.match.lon ? parseFloat(props.match.lon) : 0;

  const { setProcessData } = actionDispatchProcessData(useDispatch());
  const { setGeoLocation } = actionDispatchGeoLocation(useDispatch());
  const { setFilter } = actionDispatchFilter(useDispatch());
  const { setAutocomplete } = actionDispatchAutocomplete(useDispatch());
  const { setTargetLocation } = actionDispatchTargetLocation(useDispatch());
  const { setLanguage } = actionDispatchLanguage(useDispatch());

  const stateSelector = createSelector(makeSelectProcessDatas, (processDatas) => ({
    processDatas,
  }));

  const stateSelectorGeoLocation = createSelector(makeSelectGeoLocation, (geoLocation) => ({
    geoLocation,
  }));

  const stateSelectorFilter = createSelector(makeSelectFilter, (filter) => ({
    filter,
  }));

  const stateSelectorAutocomplete = createSelector(makeSelectAutocomplete, (autocomplete) => ({
    autocomplete,
  }));

  const stateSelectorTargetLocation = createSelector(makeSelectTargetLocation, (targetAddress) => ({
    targetAddress,
  }));

  const stateSelectorLanguage = createSelector(makeSelectLanguage, (language) => ({
    language,
  }));

  var { processDatas } = useSelector(stateSelector);
  var { geoLocation } = useSelector(stateSelectorGeoLocation);
  var { filter } = useSelector(stateSelectorFilter);
  var { autocomplete } = useSelector(stateSelectorAutocomplete);
  var { targetAddress } = useSelector(stateSelectorTargetLocation);
  var { language } = useSelector(stateSelectorLanguage);

  if (
    localStorageLanguage !== null &&
    currentLanguage !== localStorageLanguage &&
    (localStorageLanguage === 'fi' || localStorageLanguage === 'sv' || localStorageLanguage === 'en')
  ) {
    changeLanguage(localStorageLanguage);
  }

  const sideBarSize = '250px';
  const fetchProcessData = async () => {
    isLoaded = true;
    let url = 'https://etsiparkki.fi/api/resources/parking/v1/places/status/parkingplaces?filter=parking.' + targetCity;
    let response = await Axios.get(url).catch((err) => {
      console.log('err:', err);
    });
    // console.log('fetchProcessData', isLoaded);
    if (typeof response != 'undefined' && 'data' in response) {
      isLoaded = true;

      setProcessData(response.data);
    } else {
      isLoaded = false;
    }
  };

  if (isLoaded === false) fetchProcessData();

  useEffect(() => {
    console.log('page:', window);
    setInterval(async () => {
      fetchProcessData();
    }, 1000 * 360);
  }, []);

  const handleFilterChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
  };

  const typeFilterChange = (filters) => {
    console.log(`Option selected:`, filters);
    //  console.log(filters, temp);

    setFilter(filters);
  };

  const openSideBar = () => {
    document.getElementById('mySidebar').style.width = '250px';
  };

  const openFeedbackForm = () => {
    window.location.assign('/feedback/' + i18n.language);
  };

  const searchForAddress = (text) => {
    getAutoComplete(text).then((autocomplete) => {
      console.log('result', autocomplete);
      setAutocomplete(autocomplete);
    });
  };

  const selectedAddressItem = (item) => {
    setTargetLocation(item);
  };

  let activeCity = localStorage.getItem('city');

  if (activeCity !== 'null' && activeCity !== null && activeCity !== '' && !window.location.href.includes('/map/' + activeCity)) {
    console.log('activeCity', activeCity);
    window.location.assign('/map/' + activeCity);
  }
  return (
    <div>
      {isValidDomain ? (
        <div
          className="main"
          style={{
            overflow: 'hidden',
            display: 'flex',
            flexWrap: 'wrap',
            //width: '100%',
            // height: '100%',
            //    alignItems: 'center',
            //     justifyContent: 'center',
            //      postition: 'absolute',
            //flexFlow: 'row wrap',
          }}>
          <div
            className="mapView"
            key="mapView"
            style={{
              maxWidth: '100%',
              width: '100%',
              maxHeight: '100vh',
              height: '100vh',

              //float: 'left',
              //  flex: '1 0 100%',
              //    flexGrow: 1,
              //  position: 'relative',
            }}>
            {isLoaded === true && (
              <MainMap
                processData={processDatas}
                filter={filter}
                targetAddress={targetAddress}
                targetCity={targetCity}
                geoLocation={geoLocation}
                targetLat={targetLat}
                targetLon={targetLon}
                adWasSeen={(id) => {
                  let seenAds = sessionStorage.getItem('seenAds');
                  let newAd = false;
                  console.log('Mainos!');
                  if (seenAds === null) {
                    let adItems = {};
                    adItems[id] = true;
                    newAd = true;
                    sessionStorage.setItem('seenAds', JSON.stringify(adItems));
                  } else {
                    let seenAdsObject = JSON.parse(seenAds);

                    if (!(id in seenAdsObject) || seenAdsObject[id] === false) newAd = true;
                    seenAdsObject[id] = true;
                    sessionStorage.setItem('seenAds', JSON.stringify(seenAdsObject)); //Remove, so that advertises can roll.....
                  }
                  //  postEvent(id, { actionType: 'adWasVisible', target: 'ads' }).then((result) => {});
                }}></MainMap>
            )}
          </div>
          <div className="cityDropDown">
            <DropDown
              key={'cityPick' + t('cityPick')}
              controlMode="external"
              label="City"
              items={['turku']}
              valueChange={(value) => {
                console.log(value);
                localStorage.setItem('city', value);
                window.location.assign('/map/' + value);
              }}
              placeholder={t('cityPick')}
              value={activeCity}></DropDown>
          </div>
          <div className="propertyBoxGroup">
            {window.location.href.includes('/map/turku') ? (
              <div key="searchbar1" className="searchbar1">
                <SearchBar
                  handleChange={handleFilterChange}
                  filteredOptions={autocomplete}
                  autofillFetch={(text) => searchForAddress(text)}
                  selectedItem={(text) => selectedAddressItem(text)}
                  searchPlaceHolder={t('searchPlaceHolder')}></SearchBar>
              </div>
            ) : null}
          </div>
          <div className="infobar">
            <div className="companyLogo">
              <a href="https://www.swarco.com/" target={'_blank'} rel={'noreferrer'}>
                <img src="/SWARCO_Logo_white.png" alt="ysp" width="130" height="30"></img>
              </a>
            </div>
            <div className="serviceLinks">
              <div
                className="reservationsLink"
                title="https://etsiparkki.fi/reservations"
                style={{ width: '50px' }}
                onClick={() => {
                  window.location.assign('https://etsiparkki.fi/reservations');
                }}>
                <button>
                  <MdDirectionsBike></MdDirectionsBike>
                </button>
              </div>
            </div>
          </div>
          <div className="sidebar1">
            <SideBarT
              width={sideBarSize}
              onClick={() => openFeedbackForm()}
              typeFilterChange={(checked, id) => typeFilterChange(checked, id)}
              language={currentLanguage}></SideBarT>
          </div>
          <div className="header">
            <button className="openSideBarBtn" onClick={openSideBar}>
              <FiMenu></FiMenu>
            </button>

            <div className="languages">
              {currentLanguage !== 'fi' && <ImageButton src="/fi.png" onClick={() => changeLanguage('fi')}></ImageButton>}
              {currentLanguage !== 'sv' && <ImageButton src="/swe.png" onClick={() => changeLanguage('sv')}></ImageButton>}
              {currentLanguage !== 'en' && <ImageButton src="/en.png" onClick={() => changeLanguage('en')}></ImageButton>}
            </div>

            <div className="cookieWindow">
              {window.location.href.includes('https://etsiparkki.fi') && (
                <script id="CookieDeclaration" src="https://consent.cookiebot.com/51331d02-348d-4049-b6a1-7aa7235cb504/cd.js" type="text/javascript" async></script>
              )}
            </div>
          </div>
        </div>
      ) : (
        <b>Ota yhteyttä palveluntarjoajaan, mikäli haluat upottaa palvelun omalle sivulle.</b>
      )}
    </div>
  );
}

/*
 */
