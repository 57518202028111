import { SET_PROCESSDATA } from '../actions/processData';

const defaultState = {
  loaded: true,
};

function processData(state = defaultState, action) {
  switch (action.type) {
    case SET_PROCESSDATA:
      return {
        ...state,
        processDatas: action.payload,
      };
    default:
      return state;
  }
}

export default processData;
