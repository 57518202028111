import { combineReducers } from 'redux';
//import { connectRouter } from 'connected-react-router';

//import store from '../containers/MainPage/reducers';
import mainPage from '../containers/MainPage/reducers';

import processData from './processData.js';

const createRootReducer = (history) =>
  combineReducers({
    // router: connectRouter(history),

    mainPage,
  });

export default createRootReducer;
