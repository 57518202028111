import React from 'react';

class TestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigatorLocation: {},
      navigatorLocationWatch: {},
      testKey: 'i am a test',
      error: false,
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.watchID = navigator.geolocation.watchPosition(
      (position) => {
        this.setState({ navigatorLocationWatch: position.coords });
        // doSomething(position.coords.latitude, position.coords.longitude);
      },
      function error(err) {
        console.log(`ERROR(${err.code}): ${err.message}`);
        this.setState({ navigatorLocationWatch: { error: true } });
        alert('error with watch');
      },
      {
        enableHighAccuracy: false,
        timeout: 20000,
        maximumAge: 0,
      }
    );
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
  }

  render() {
    const { navigatorLocationWatch, navigatorLocation, testKey } = this.state;
    const { width } = this.state;
    console.log('render', testKey, navigatorLocation);
    const refresh = () => {
      var that = this;
      console.log('refresh');
      navigator.geolocation.getCurrentPosition(
        (positionCoordinates) => {
          // const latlng = { lat: position.coords.latitude, lon: position.coords.longitude, radius: position.coords.radius };

          // let gpsCoordinates = { ...position.coords, ...position.accuracy };
          console.log('position', positionCoordinates, testKey);
          this.setState({ navigatorLocation: positionCoordinates.coords, testKey: 'whoo', error: false });
          // map.flyTo([positionCoordinates.coords.latitude, positionCoordinates.coords.longitude]);
        },
        function error(err) {
          console.log(`ERROR(${err.code}): ${err.message}`);
          this.setState({ error: true, testKey: 'errr' });
        },
        {
          enableHighAccuracy: false,
          maximumAge: 0,
          timeout: 10000,
        }
      );
    };
    console.log('latitude:', navigatorLocation?.latitude);
    return (
      <div>
        <div>I am a test page :-)</div>
        <div>
          <button
            onClick={() => {
              refresh();
            }}>
            refresh coords.
          </button>
        </div>

        <div>{'watch, lat:' + navigatorLocationWatch?.latitude + ' long:' + navigatorLocationWatch?.longitude + ' accuracy:' + navigatorLocationWatch?.accuracy}</div>

        <div>{'poll, lat:' + navigatorLocation?.latitude + ' long:' + navigatorLocation?.longitude + ' accuracy:' + navigatorLocation?.accuracy}</div>
      </div>
    );
  }
}

export default TestPage;
