import { ActionTypes } from './constants';

const defaultState = {
  processDatas: [],
  geoLocation: {},
  filter: {},
  autocomplete: [],
  language: 'fi',
};

export default function mainPageReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.SET_PROCESSDATA:
      return { ...state, processDatas: action.payload };
    case ActionTypes.SET_GEOLOCATION:
      return { ...state, geoLocation: action.payload };
    case ActionTypes.SET_FILTER:
      return { ...state, filter: action.payload };
    case ActionTypes.SET_AUTOCOMPLETE:
      return { ...state, autocomplete: action.payload };
    case ActionTypes.SET_TARGET_LOCATION:
      return { ...state, targetLocation: action.payload };
    case ActionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload };

    default:
      return state;
  }
}
