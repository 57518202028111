let sv = {
  translations: {
    directions: 'Vägbeskrivning',
    about: 'About',
    info: 'Info',
    freeParking: 'Gratis parkering',
    discParking: 'Parkeringsskiva',
    tollParking: 'Avgiftsparkering',
    typeCDparking: 'CD-parkering',
    feedback: 'Feedback',
    specialParking: 'Särskild parkering',
    privateArea: 'Privat område',
    streetParking: 'Gatuparkering',
    parkinglot: 'Parkeringsplats',
    allFilters: 'Alla filter',
    searchPlaceHolder: 'Adress sökning...',
    city: 'Stad',
    segment: 'Segmentet',
    assetType: 'Typ av område',
    capacity: 'Kapacitetsuppskattning',
    paymentType: 'Betalnings typ',
    paymentZone: 'Betalningszon',
    reservedForTypes: 'Reserverad för fordon',
    parkingCount: 'Pågående parkering',
    parkingPlace: 'Parkeringsplats',
    discLimitedOnWeek: 'Begränsning på skivan på vardagar',
    discLimitedOnSat: 'Begränsning på skivan på lördag',
    discLimitedOnSun: 'Begränsning på skivan på söndag',
    restrictionAdditionalInformation: 'Ytterligare information om begränsningar',
    privateAreaInformation: 'Ytterligare information',
    limitedPaymentOnWeek: 'Betalas på vardagar',
    limitedPaymentOnSat: 'Betalas på lördag',
    limitedPaymentOnSun: 'Betalas på söndag',
    type: 'Typ',
    maxAllowedTime: 'Högsta tillåtna parkeringstid',
    freeInfo: 'Fri',
    fullInfo: 'Reserverad',
    unknownInfo: 'Okänt',
    feedbackFormHeader: 'Vad tycker du om tjänsten?',
    otherFeedback: 'Annan feedback:',
    submitButton: 'Överlämna',
    missingFeedback: 'Välj en smiley och skicka in',
    feedbackThanks: 'Tack för feedbacken!',
    otherInfo: 'obs',
    sharedInfoAccrossStreet: 'Antalet platser är gemensamt för det motsatta området',
    disclaimerNotice: 'Information om beläggning är vägledande',
    dataSource: 'Datakälla',
    linkToMainPageText: 'Hitta parkering',
    specialPermissions: 'Tillåtet med särskilt tillstånd',
    LElicense: 'Invalidparkering ',
    CDplate: 'CD-platta',
    bicycle: 'Cykel',
    advertisement: 'Annons',
    space: 'plats',
    full: 'full',
    cityPick: 'stad',
    description: 'Beskrivning',
  },
  parkingCountIndicator: {
    free: 'SPACES',
    full: 'FULL',
    closed: 'STÄNGD',
  },
};

export default sv;
