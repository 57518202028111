import React from 'react';

import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Routes, useParams, Navigate } from 'react-router-dom';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//import store1 from './store';
import './i18n';
import { createRoot } from 'react-dom/client';
import { MainPage } from './containers/MainPage';
import { FeedBack } from './containers/Feedback';
import TestPage from './containers/TestPage';

//import { UnderConstruction } from './containers/UnderConstruction';
import createRootReducer from './reducers';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const history = createBrowserHistory();
const store = createStore(createRootReducer(history), applyMiddleware(thunk));
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
} else {
  window.getState = store.getState;
}
root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <Routes history={history}>
        <Route exact path="/" element={<MainF></MainF>} />
        <Route exact path="/map" element={<MainPage></MainPage>} />
        <Route path="/map/location/:lat/:lon" element={<MainF></MainF>} />
        <Route path="/map/:city" element={<MainF></MainF>} />
        <Route path="/feedback/:language" element={<FeedBackF></FeedBackF>} />
        <Route path="/ui/test/testpage" element={<TestPage></TestPage>} />
        <Route path={'*'} element={<Navigate to={'/'}></Navigate>} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
function MainF() {
  let params = useParams();
  return <MainPage match={params}></MainPage>;
}

function FeedBackF() {
  let params = useParams();
  return <FeedBack match={params}></FeedBack>;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//<Route exact path="/" component={MainPage} />
