import join from 'url-join';
import { validateResponse, toJson } from '../api/apiUtils';
import { API_ROOT } from '../api/apiUtils';

const DEVICE_ROOT = join(API_ROOT, 'address');

/**
 * Get addresses
 * @returns { Promise }
 */
export function getAutoComplete(pattern) {
  let path = join(DEVICE_ROOT, 'autocomplete');
  path = path + '?pattern=' + pattern;

  return fetch(path).then(validateResponse).then(toJson);
}
