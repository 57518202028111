export const API_ROOT = 'https://etsiparkki.fi/api/resources';
//export const API_ROOT = 'http://localhost:8080/api';
export const CONTENT_ROOT = '';
export const WEBSOCKET_ROOT = '';

export function validateResponse(response) {
  if (response.ok) return response;
  return response.text().then((message) => Promise.reject(response));
}

export function toJson(response) {
  return response.json().catch(() => true);
}
