import React, { Component, useState, useEffect, useRef, useMemo, Suspense } from 'react';
import Gauge from '../../components/gauges/gauge';

import ReactDOMServer from 'react-dom/server';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  useMap,
  LayersControl,
  Polygon,
  Polyline,
  AttributionControl,
  useMapEvents,
  Tooltip,
  Circle,
} from 'react-leaflet';
import { useTranslation, Trans } from 'react-i18next';

import L from 'leaflet';

//import HeatmapLayer from '../src/HeatmapLayer';
import './MainMap.css';

import { postEvent } from '../../api/events';
//import { map } from 'leaflet';
import { getAds } from '../../api/ads';

import { findNearest } from 'geolib';
import { IoMdLocate } from 'react-icons/io';
import { IoInformationOutline } from 'react-icons/io5';
import PopUp from 'reactjs-popup';
import Measurement from '../../components/indicators/Measurement/Measurement';
import SVGIconComponent from '../../components/protos/customObject';
const CapacityIndicator = React.lazy(() => import('../../components/parking/CapacityIndicator'));
//import L from 'leaflet';

const cityLocations = {
  turku: [60.452486, 22.258],
  helsinki: [60.167486, 24.938],
  jyvaskyla: [62.22733263465443, 25.745329303178632],
  tampere: [61.497531077430274, 23.761863003254685],
  // oulu: [65.00816171497313, 25.52351513800427],
};
var position = cityLocations.turku;
//var position = [60.452486, 22.258]; //TKU
//var position = [60.167486, 24.938]; //HKI

const addressPoints = [
  [60.452486, 22.258, '571'],
  [60.452586, 22.259, '486'],
  [60.452686, 22.257, '807'],
];

//const fillBlueOptions = { fillColor: 'blue' };
const polygonPathOptionsFreePlace = { color: 'blue', weight: 5, opacity: 0.5 };
const polygonPathOptionsFullPlace = { color: 'orange', weight: 5, opacity: 0.5 };
const polygonPathOptionsUnknown = { color: 'black', weight: 5, opacity: 0.5 };
var clikCoordinates = {};
function getLabel(value) {
  return value + '%';
}

function AssetIndicators(props) {
  const [zoomLevel, setZoomLevel] = useState(props.initialZoom); // initial zoom level provided for MapContainer
  const [showAlarms, setShowAlarms] = useState(false);
  const [position, setPosition] = useState(null);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  // console.log('zoomLevel', zoomLevel);
  // console.log('showAlarms', showAlarms);
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
      //  console.log(zoomLevel);
    },
  });

  if (!('asset' in props)) return null;
  if (zoomLevel < props.zoomLimit) return null;
  let assetObject = props['asset'];

  let assetId = Object.keys(assetObject)[0];
  let item = assetObject[assetId];
  const idElements = assetId.split('.');
  const assetType = idElements.length === 4 ? idElements[2] : '';
  const id = idElements[idElements.length - 1];

  if (!item.asset || !('area' in item.asset) || !('coordinates' in item.asset.area)) return null;
  const capacity = 'asset' in item && 'data' in item.asset && 'capacity' in item.asset.data ? item.asset.data.capacity : null;
  const parkingCount = 'processdata' in item && 'data' in item.processdata && 'parkingCount' in item.processdata.data ? item.processdata.data.parkingCount : null;
  const freeCount = 'processdata' in item && 'data' in item.processdata && 'freeCount' in item.processdata.data ? item.processdata.data.freeCount : null;
  //debugger;
  const coordinates = item.asset.area.coordinates;
  let coordinateArray;
  //console.log(Array.isArray(coordinates));
  if (Array.isArray(coordinates)) {
    if (Array.isArray(coordinates[0])) {
      if (Array.isArray(coordinates[0][0])) {
        if (Array.isArray(coordinates[0][0][0])) {
          coordinateArray = coordinates[0][0][0];
        }
      } else coordinateArray = coordinates[0][0];
    } else coordinateArray = coordinates[0];
  } else coordinateArray = coordinates;

  if (coordinateArray === undefined || coordinateArray === null || coordinateArray.length < 2) return null;
  // const centerPoint = getCenter(coordinateArray);
  //console.log(coordinateArray);
  //react divIcon example
  //https://medium.com/@nikjohn/creating-a-dynamic-jsx-marker-with-react-leaflet-f75fff2ddb9
  let icon = null;
  let scale = zoomLevel / 16;
  if (scale > 1) scale = 1;
  else {
    scale = scale / 1.4;
  }
  if (assetType === 'parkingplace') {
    icon = L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(
        <div style={{ transform: 'scale(' + scale + ')' }}>
          <Suspense fallback={<div>...</div>}>
            <CapacityIndicator showAlarms={showAlarms} id={assetId} capacity={capacity} parkingCount={parkingCount} freeCount={freeCount} description={''} />
          </Suspense>
        </div>
      ),
    });
  }
  /*
  setTimeout(() => {
    var elem = document.getElementById('icon-' + assetId + '-alarms');
    if (elem != null) {
      // elem.removeEventListener('click', () => setShowAlarms(false));
      //debugger;
      console.log('add eventlistener', assetId, elem);
      // elem.addEventListener('click', () => setShowAlarms(true));
    }
  }, 500);*/

  return icon != null ? (
    <Marker
      position={position == null ? reverseLonLat(coordinateArray) : position}
      icon={icon}
      draggable={false}
      eventHandlers={{
        click: (e) => {
          props.markerClick({ id: assetId, event: { x: e.containerPoint.x, y: e.containerPoint.y } });
        },
      }}></Marker>
  ) : null;
}

function GeoObject(props) {
  const { t } = useTranslation();
  //debugger;
  if (!('asset' in props)) return null;
  let assetObject = props['asset'];
  // console.log(assetObject);
  let assetId = Object.keys(assetObject)[0];
  let item = assetObject[assetId];
  let geometries = [];
  if (!('asset' in item)) return null;
  if (!('area' in item.asset)) return null;
  if ('geometries' in item.asset.area) {
    geometries = item.asset.area.geometries; //GeometryCollection
  } else geometries.push(item.asset.area); //

  let metadata = item.asset.data;
  // if (props['idx'] < 2) console.log(item.asset);
  // if (props['idx'] >= 0) return null;

  let skipPaymentTypeFilter = false;
  for (let filter in props.filter.reservedForTypes) {
    if (!('reservedForTypes' in metadata)) return null;

    if (filter !== metadata.reservedForTypes) {
      //    console.log('filter out ' + assetId);
      return null;
    }
    skipPaymentTypeFilter = true;
  }

  if (skipPaymentTypeFilter === false) {
    for (let filter in props.filter.paymentType) {
      if (props.filter.paymentType[filter] === false) continue;
      if (!('paymentType' in metadata)) return null;

      if ('paymentType' in metadata) {
        if (filter === metadata.paymentType) {
          //    console.log('filter out ' + assetId);
          return null;
        }
      } else {
        return null;
      }
    }
  }

  //if (props.filter.length < 1) return null; //just for filter testing

  let coordinates = [];
  var reversedCoordinates = [];
  var polygonList = [];
  geometries.forEach((element1, idx1) => {
    //   console.log('element1', element1);
    let type = element1.type;
    if (type === 'MultiPolygon') {
      coordinates = element1['coordinates'];
    } else {
      coordinates[0] = element1['coordinates'];
    }

    /*if (assetId === 'parking.turku.parkingplace.f9edb154-57ec-444b-92b3-6331a8141048') {
      console.log(assetId + '  coords:', element1['coordinates']);
    }*/

    //   console.log('coordinates 1', coordinates.length);
    if (!Array.isArray(coordinates)) {
      console.log(assetId + ' is missing coordinates');
      return null;
    }
    //Reverse coordinates
    coordinates.forEach((element2, idx2) => {
      if (Array.isArray(element2)) {
        reversedCoordinates[idx2] = [];
        element2.forEach((element3, idx3) => {
          if (element3.length === 0) {
            console.log(assetId, ' blank');
            return null;
          }
          reversedCoordinates[idx2][idx3] = [];
          element3.forEach((element4, idx4) => {
            reversedCoordinates[idx2][idx3][idx4] = new Array(2);
            reversedCoordinates[idx2][idx3][idx4][0] = element4[1];
            reversedCoordinates[idx2][idx3][idx4][1] = element4[0];
          });

          // console.log(idx2, idx3, element3);
        });
        if (JSON.stringify(reversedCoordinates[idx2][0]) === JSON.stringify(reversedCoordinates[idx2][reversedCoordinates[idx2].length - 1])) {
          if (reversedCoordinates[idx2].length === 0) {
            console.warn('reversedCoordinates length is 0', assetId);
            return null;
          }
          //  reversedCoordinates[idx3].length = reversedCoordinates[idx3].length - 1;
        }
      } else {
        reversedCoordinates[idx2] = element2.reverse();
      }
      // console.log('coordinates after reverse', reversedCoordinates);
    });

    const popUpInfo = [];
    const progressInfo = [];
    const keyId = assetId + idx1;
    if ('maxAllowedHours' in metadata && metadata.maxAllowedHours === 0) delete metadata.maxAllowedHours;

    if ('maxAllowedMinutes' in metadata && metadata.maxAllowedMinutes === 0) delete metadata.maxAllowedMinutes;

    for (let metaItem in metadata) {
      if (
        metaItem === 'city' ||
        metaItem === 'segment' ||
        metaItem === 'assetType' ||
        metaItem === 'capacity' ||
        metaItem === 'dayException' ||
        metaItem === 'seasonException'
      )
        continue;
      if (String(metadata[metaItem]).length === 0) continue;
      popUpInfo.push(
        <tr key={keyId + metaItem} className="popUpTableRow">
          <td>{t(metaItem)}:</td>
          <td> {t(metadata[metaItem])}</td>
        </tr>
      );
    }
    /*

    */

    if ('capacity' in metadata) {
      popUpInfo.push(
        <tr key={keyId + 'capacity'} className="popUpTableRow">
          <td>{t('capacity')}:</td>
          <td> {t(metadata['capacity'])}</td>
        </tr>
      );
    }
    if ('processdata' in item && 'data' in item.processdata && 'freeCount' in item.processdata.data) {
      popUpInfo.push(
        <tr key={keyId + 'freeCount'} className="popUpTableRow">
          <td>{t('freeCount')}:</td>
          <td> {item.processdata.data['freeCount']}</td>
        </tr>
      );
    }
    if ('processdata' in item && 'data' in item.processdata && 'parkingCount' in item.processdata.data) {
      popUpInfo.push(
        <tr key={keyId + 'data.parkingCount'} className="popUpTableRow">
          <td>{t('dataSource')}:</td>
          <td> {'source' in item.processdata.data && item.processdata.data.source}</td>
        </tr>
      );
    }

    const popUpTable = (
      <table key={keyId + 'metatable'}>
        <tbody>{popUpInfo}</tbody>
      </table>
    );

    //  reversedCoordinates.forEach((element, idxGeometry) => {
    var pathOptions = getParkingPlaceStatus(metadata, 'processdata' in item ? item.processdata : {});

    if (reversedCoordinates.length === 0) return null;
    let targetCoordinate = reversedCoordinates[0][0];
    /*if (assetId === 'parking.turku.parkingplace.f9edb154-57ec-444b-92b3-6331a8141048') {
      console.log('asset debug:', targetCoordinate, reversedCoordinates);
    }*/

    if (targetCoordinate === 'undefined' || !Array.isArray(targetCoordinate)) {
      console.log(assetId + ' has malformed coordinates');
      return null;
    }

    if (idx1 > 0) {
      console.debug('idx > 0', keyId);
    }
    polygonList.push(
      <Polygon
        key={keyId}
        id={keyId}
        pathOptions={pathOptions}
        positions={reversedCoordinates}
        eventHandlers={{
          click: (e) => {
            clikCoordinates = e.latlng;
            //postEvent(assetId, { actionType: 'userClick', target: 'popUpView' }).then((result) => {});
          },
        }}>
        <Popup
          closeButton={true}
          onOpen={() => {
            console.log('open ' + keyId);
          }}
          onClose={() => console.log('close ' + keyId)}>
          {popUpTable} <br></br>
          {progressInfo}
          {'processdata' in item && 'data' in item.processdata && 'parkingCount' in item.processdata.data && 'capacity' in metadata ? (
            <div className="gauge">
              {true ? (
                <Gauge
                  hideValue={item.processdata.data.parkingCount < metadata.capacity ? false : true}
                  invert
                  label={item.processdata.data.parkingCount < metadata.capacity ? t('space') : t('full')}
                  width={80}
                  max={metadata.capacity}
                  value={item.processdata.data.parkingCount <= metadata.capacity ? item.processdata.data.parkingCount : metadata.capacity}></Gauge>
              ) : null}
            </div>
          ) : null}
          <a
            href={'https://www.google.fi/maps/dir//' + targetCoordinate[0][0] + ',' + targetCoordinate[0][1] + '/@' + position[0] + ',' + position[1] + ',14z'}
            onClick={() => {
              //postEvent(assetId, { actionType: 'userClick', target: 'routeDirections' }).then((result) => {});
            }}>
            {t('directions')}
          </a>
          <br></br>
          <br></br>
          {props.adContent !== '' && (
            <div>
              <i>{t('advertisement')}</i>
              <br></br>
              <a href={props.adLink}>
                <img src={props.adContent} alt="ad content" width="280" height="40" onLoad={() => props.adWasSeen(props.adId)}></img>
              </a>
            </div>
          )}
        </Popup>
      </Polygon>
    );
    //  });
  });

  /*
 

*/

  /*if (assetId === 'parking.turku.parkingplace.f9edb154-57ec-444b-92b3-6331a8141048' || assetId === 'parking.turku.parkingplace.270') {
    console.log(assetId, polygonList);
  }*/
  return polygonList;
}

function reverseLonLat(coordinate) {
  let lat = coordinate[1];
  let lon = coordinate[0];
  let reversed = [lat, lon];
  //console.log(reversed);
  return reversed;
}

function getParkingPlaceStatus(metadata, processdata) {
  //debugger;
  if ('data' in processdata && 'freeCount' in processdata.data && 'parkingState' in processdata.data) {
    if (processdata.data.parkingState === 'closed') return polygonPathOptionsUnknown;
    else {
      if (processdata.data.freeCount > 0) return polygonPathOptionsFreePlace;
      else return polygonPathOptionsFullPlace;
    }
  }
  if (!('capacity' in metadata) || !('data' in processdata) || !('parkingCount' in processdata.data)) return polygonPathOptionsUnknown;
  let isFree = processdata.data.parkingCount < metadata.capacity ? true : false;

  return isFree ? polygonPathOptionsFreePlace : polygonPathOptionsFullPlace;
}

function LocationMarker(targetAddress) {
  const [clickPosition, setClickPosition] = useState(null);
  const map = useMap();
  const mapEvents = useMapEvents({
    click(args) {
      map.eachLayer(function (layer) {
        var id = layer.options.id;
        if (id !== 'CustomGeoObjects') return;

        let geoArray = [];
        let geoArrayWithId = {};
        for (let item in layer._layers) {
          let itemId = layer._layers[item]._leaflet_id;
          let centerOfObject = layer._layers[item].getCenter();
          //  console.debug('object center', centerOfObject);
          geoArray.push(centerOfObject);
          geoArrayWithId[JSON.stringify(centerOfObject)] = itemId;
        }
        let nearestObject = findNearest(args.latlng, geoArray);
        //  console.debug('nearestObject', nearestObject);
        if (JSON.stringify(nearestObject) in geoArrayWithId) {
          //  console.debug(geoArrayWithId[JSON.stringify(nearestObject)]);
          map._layers[geoArrayWithId[JSON.stringify(nearestObject)]].openPopup();
        }
      });
      setClickPosition(args.latlng);
    },
  });

  return true ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

function NavigatorMarker(props) {
  if (!('coords' in props.navigatorLocation) || !('latitude' in props.navigatorLocation.coords)) return null;

  let radius = props.navigatorLocation.coords.accuracy === 'undefined' || props.navigatorLocation.coords.accuracy > 4500 ? 1 : props.navigatorLocation.coords.accuracy;
  return (
    <Circle
      center={[props.navigatorLocation.coords.latitude, props.navigatorLocation.coords.longitude]}
      radius={radius}
      pathOptions={{ fillColor: 'black', opacity: 0.4, fillOpacity: 0.1 }}></Circle>
  );
}

function InfoPopUp() {
  const { t } = useTranslation();
  return (
    <PopUp
      trigger={
        <button className="infoBtn">
          <IoInformationOutline></IoInformationOutline>
        </button>
      }
      modal={false}
      nested
      closeOnDocumentClick
      position="right center">
      {(close) => (
        <div className="infoPopUp">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="popUpHeader"></div>
          <div className="popUpContent">
            {' '}
            <table>
              <tbody>
                <tr>
                  <th>
                    <img src="/placeFree.png" alt="placeFree" width="80" height="25"></img>
                  </th>
                  <th>{t('freeInfo')}</th>
                </tr>
                <tr>
                  <th>
                    <img src="/placeFull.png" alt="placeFull" width="80" height="25"></img>
                  </th>
                  <th>{t('fullInfo')}</th>
                </tr>
                <tr>
                  <th>
                    <img src="/placeUnknown.png" alt="placeUnknown" width="80" height="25"></img>
                  </th>
                  <th>{t('unknownInfo')}</th>
                </tr>
              </tbody>
            </table>
            <br></br>
            <div className="infoPopUpNotice" style={{ textAlign: 'left' }}>
              <b>{t('disclaimerNotice')}</b>
            </div>
          </div>
          <div className="actions"></div>
        </div>
      )}
    </PopUp>
  );
}

class MainMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      navigatorLocation: {},
      ads: [],
      adContent: '',
      adLink: '',
      adId: '',
    };
    //    this.MapContainer = React.createRef();
  }

  updateAds() {
    const { map, ads } = this.state;
    if (map !== null) {
      let mapCenter = map.getCenter();
      let mapZoom = map.getZoom();
      //  console.debug(ts.toString() + '  map.getCenter', map.getCenter(), map.getZoom());
      if (mapZoom >= 14) {
        getAds(mapCenter.lat, mapCenter.lng).then((advertisements) => {
          //    console.debug('ads', advertisements);

          if (JSON.stringify(advertisements) !== JSON.stringify(ads)) {
            let adItem = {};
            let id = '';
            let link = '';

            if (advertisements.length > 0) {
              adItem = advertisements[0];
              id = Object.keys(adItem)[0];
              if ('asset' in adItem[id] && 'data' in adItem[id].asset && 'link' in adItem[id].asset.data) {
                link = adItem[id].asset.data.link;
              }
            }
            let contentUrl = id === '' ? '' : 'https://etsiparkki.fi/api/resources/ads/advertisement/content?id=' + id;
            this.setState({ ads: advertisements, adContent: contentUrl, adLink: link, adId: id });
          }
        });
      } else {
        if (ads.length !== 0) this.setState({ ads: [] });
      }
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      //   this.updateAds();
    }, 1000 * 23);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    //console.debug('map willlUnmount');
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('mainMap targetAddress:', this.props.targetAddress);
    console.debug('mainMap processItems:', this.props.processData.length);
    // console.log('mainMap targetCity:', this.props.targetCity);
    //console.log('mainMap geoLocation:', this.props.geoLocation);
    // console.log('mainMap filter:', this.props.filter);
    const { map } = this.state;
    if (this.props.targetAddress && this.props.targetAddress.lat && this.props.targetAddress.lon) {
      if (map && JSON.stringify(position) !== JSON.stringify([this.props.targetAddress.lat, this.props.targetAddress.lon])) {
        position[0] = this.props.targetAddress.lat;
        position[1] = this.props.targetAddress.lon;
        map.flyTo(position);
      }
    }

    //  map.on('locationfound', this.handleOnLocationFound());
  }

  locationSuccess(position) {
    console.log(position);
    const latlng = { lat: position.latitude, lon: position.longitude };
    this.setState({ navigatorLocation: latlng });
  }

  locationError() {
    console.warn('no position available.');
  }

  locateClick() {
    const { map } = this.state;
    if (!('latitude' in this.props.geoLocation)) {
      navigator.geolocation.getCurrentPosition(
        (positionCoordinates) => {
          // const latlng = { lat: position.coords.latitude, lon: position.coords.longitude, radius: position.coords.radius };

          // let gpsCoordinates = { ...position.coords, ...position.accuracy };
          console.log('position', positionCoordinates);
          this.setState({ navigatorLocation: positionCoordinates });
          map.flyTo([positionCoordinates.coords.latitude, positionCoordinates.coords.longitude]);
        },
        this.locationError,
        {
          enableHighAccuracy: false,
          maximumAge: 0,
          timeout: 5000,
        }
      );
      return;
    }
  }

  mapCreated(ref) {
    const { map } = this.state;
    if (map == null) this.setState({ map: ref });
  }

  render() {
    let targetMarker;
    let addressPoints = [];
    const { navigatorLocation, ads, adContent, adLink, adId } = this.state;
    console.log('mainMap navigatorLocation', navigatorLocation);
    let mapCenter = [];
    if (this.props.targetAddress && this.props.targetAddress.lat && this.props.targetAddress.lon) {
      targetMarker = (
        <Marker key="myMarker" position={[this.props.targetAddress.lat, this.props.targetAddress.lon]}>
          <Popup key="myPopup" closeButton={true}>
            {this.props.targetAddress.key} <br />
          </Popup>
        </Marker>
      );
    }
    if (this.props.targetLat && this.props.targetLon && this.props.targetLat !== 'NaN' && this.props.targetLon !== 'NaN') {
      mapCenter = [this.props.targetLat, this.props.targetLon];
    } else if (this.props.targetCity) {
      if (this.props.targetCity in cityLocations) {
        mapCenter = cityLocations[this.props.targetCity];
      } else mapCenter = cityLocations['turku'];
    } else mapCenter = cityLocations['turku'];

    console.log(this.props);

    //custom icon Demo
    const testPosition = [65.00773551114395, 25.52336145063733];
    const testPosition2 = [65.00773551114395, 25.52336145063733];
    const icon = L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(<Measurement value={24} description={'.........'} />),
    });

    const icon2 = L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(<SVGIconComponent value={22} description={''} />),
    });
    //custom icon Demo
    return (
      <div id="map">
        {false && (
          <button className="locateBtn" onClick={() => this.locateClick()}>
            <IoMdLocate></IoMdLocate>
          </button>
        )}
        <InfoPopUp className="info"></InfoPopUp>
        <MapContainer
          className="map"
          id="map"
          key={JSON.stringify('parkingMap')}
          center={mapCenter}
          zoom={15}
          scrollWheelZoom={true}
          minZoom={10}
          maxZoom={21}
          attributionControl={false}
          zoomControl={false}
          ref={(map) => this.mapCreated(map)}>
          <TileLayer
            maxNativeZoom={18}
            className="tileLayer"
            maxZoom={21}
            tms={false}
            attribution='<a href="https://leafletjs.com">Leaflet |</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            //  url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png
            //https://kartta.jkl.fi/IMS/Tile?basemap=Opaskartta&x={x}&y={y}&z={z}
            opacity={0.5}
          />
          <LayerGroup id="CustomGeoObjects" key="CustomGeoObjects">
            {this.props.processData.map((item, idx) => (
              <GeoObject
                idx={idx}
                key={idx}
                asset={item}
                filter={this.props.filter}
                clikCoordinates={clikCoordinates}
                adContent={adContent}
                adLink={adLink}
                adId={adId}
                adWasSeen={(id) => this.props.adWasSeen(id)}></GeoObject>
            ))}
          </LayerGroup>
          <LayerGroup id="IndicatorObjects" key="IndicatorObjects">
            {this.props.processData.map((item, idx) => (
              <AssetIndicators initialZoom={15} zoomLimit={17} idx={idx} key={idx} asset={item} controlWindowOpen={false} filter={this.props.filter}></AssetIndicators>
            ))}
          </LayerGroup>
          {targetMarker}
          <LocationMarker targetAddress={this.props.targetAddress} />
          <NavigatorMarker navigatorLocation={navigatorLocation} />

          <AttributionControl position="bottomright" prefix={false} />
        </MapContainer>
      </div>
    );
  }
}

export default MainMap;

/*
normal:
https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
black/white:
https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png

Add custom object

import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

render:

 const testPosition = [65.00773551114395, 25.52336145063733];
    const icon = L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(<SVGIconComponent perc={22} />),
    });


  <Marker position={testPosition} icon={icon} />
          <Marker position={testPosition2} icon={icon2} />

*/
