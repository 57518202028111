import join from 'url-join';
import { validateResponse, toJson } from '../api/apiUtils';
import { API_ROOT } from '../api/apiUtils';

const DEVICE_ROOT = join(API_ROOT, 'ads');

/**
 * Get ads
 * @returns { Promise }
 */
export function getAds(lat, lon) {
  let path = join(DEVICE_ROOT, 'advertisement');
  path = path + '?lat=' + lat + '&lon=' + lon;

  return fetch(path).then(validateResponse).then(toJson);
}

/**
 * Get ad content
 * @returns { Promise }
 */
export function getAdContent(id) {
  let path = join(DEVICE_ROOT, 'advertisement/content');
  path = path + '?id=' + id;

  return fetch(path).then(validateResponse).then(toJson);
}
