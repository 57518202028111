import { ActionTypes } from './constants';

export const setProcessDatas = (processDatas) => ({
  type: ActionTypes.SET_PROCESSDATA,
  payload: processDatas,
});

export const setGeoLocation = (geoLocation) => ({
  type: ActionTypes.SET_GEOLOCATION,
  payload: geoLocation,
});

export const setFilter = (filter) => ({
  type: ActionTypes.SET_FILTER,
  payload: filter,
});

export const setAutocomplete = (filter) => ({
  type: ActionTypes.SET_AUTOCOMPLETE,
  payload: filter,
});

export const setTargetLocation = (address) => ({
  type: ActionTypes.SET_TARGET_LOCATION,
  payload: address,
});

export const setLanguage = (language) => ({
  type: ActionTypes.SET_LANGUAGE,
  payload: language,
});
