import join from 'url-join';
import { validateResponse, toJson } from '../api/apiUtils';
import { API_ROOT } from '../api/apiUtils';

const DEVICE_ROOT = join(API_ROOT, 'device');

/**
 * Get information about the device
 * @returns { Promise }
 */
export function getNearest() {
  let path = join(DEVICE_ROOT, 'parkingplaces');
  path = path + '?filter=parking.Turku';

  return fetch(path).then(validateResponse).then(toJson);
}
