import React from 'react';
//import './Spinner.css';
class Measurement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //this is for uiBuilder usage
  static supportedProperties = { backgroundColor: 'color', color: 'color' };
  componentDidMount() {}

  componentDidUpdate() {
    //console.log('properties:', this.properties);
  }

  render() {
    const value = this.props.hasOwnProperty('value') ? this.props.value : '-';
    const unit = this.props.unit ? ' ' + this.props.unit : '';
    const description = this.props.description ? this.props.description : '';
    return (
      <div
        className="measurement"
        style={{
          backgroundColor: 'rgba(0, 0, 0, .35)',
          color: 'white',
          //    padding: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          borderRadius: '20%',
          fontSize: '18px',
          //    clear: 'both',
          display: 'block',
          width: 'fit-content',
          minWidth: '100%',
        }}>
        <div>
          <b>{description}</b>
        </div>
        <div>
          <b>
            {value}
            {unit}
          </b>
        </div>
      </div>
    );
  }
}

export default Measurement;
